import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

const MENTION_SPLIT_MATCHER = /@\[[^[]*\]\([0-9]+\)/;
const MENTION_INFO_MATCHER = /@\[([^[]*)\]\(([0-9]+)\)/;

export interface MentionTextPropTypes {
  text: string;
  contextText?: string;
  className?: string;
}

interface Mention {
  id: number;
  name: string;
}

interface NoteTextPart {
  type: 'text' | 'context' | 'mention';
  id?: number;
  value: string;
}

const extractMentions = (text: string): Mention[] => {
  const regexp = new RegExp(MENTION_INFO_MATCHER, 'g');
  const mentions = [];
  for (let match = regexp.exec(text); match !== null; match = regexp.exec(text)) {
    mentions.push({ id: parseInt(match[2], 10), name: match[1] });
  }
  return mentions;
}

const mergeMentionsAndParts = (mentions: Mention[], parts: string[]): NoteTextPart[] => {
  const merged = [];
  while (mentions.length > 0 || parts.length > 0) {
    const mention = mentions.shift();
    const part = parts.shift();
    if (part !== undefined) merged.push({ type: 'text', value: part });
    if (mention !== undefined) merged.push({ type: 'mention', id: mention.id, value: mention.name });
  }
  return merged;
}

const renderTextPart = (textPart: NoteTextPart, index: number) => {
  if (textPart.type === 'text') return <span key={index}>{textPart.value}</span>;

  return (
    <span key={index} className={`${styles.mention} fw-bold`}>{textPart.value}</span>
  )
}

const isEmpty = (text?: String) => {
  return text == null || text.length == 0;
}

export const MentionText = React.memo(function NoteText(props: MentionTextPropTypes) {
  if (typeof props.text !== 'string') return null;

  const mentions = extractMentions(props.text);
  const parts = props.text.split(MENTION_SPLIT_MATCHER);
  const merged = mergeMentionsAndParts(mentions, parts);

  return (
    <>
      { !isEmpty(props.contextText) &&
        <span className="text-muted">
          {`${props.contextText} `}
        </span>
      }
      <span className={classNames(props.className || "text-primary")}>
        {merged.map((v, i) => renderTextPart(v, i))}
      </span>
    </>
  )
});
