import { UnprocessableEntityError } from './errors/UnprocessableEntityError';
import { ApiError } from './errors/ApiError';
import * as queryString from 'query-string';

type RequestBody = object | FormData;

interface IRequestOptions {
  headers?: IRequestHeaders;
}

interface IRequestHeaders extends Record<string, string> {
  'Accept'?: string;
  'Content-Type'?: string;
}

const defaultOptions: IRequestOptions = {
  headers: {
    'Accept': 'application/json; charset=utf-8',
    'Content-Type': 'application/json; charset=utf-8',
  },
};

export class ApiService {
  public static async get<T>(
    path: string,
    params: object = undefined,
    options: IRequestOptions = defaultOptions,
  ): Promise<T> {
    let processedPath = path;
    if (params !== undefined) {
      processedPath = `${processedPath}?${queryString.stringify(params, { arrayFormat: 'bracket' })}`;
    }
    return this.request('GET', processedPath, undefined, options.headers);
  }

  public static async post<T>(
    path: string,
    body: RequestBody,
    options: IRequestOptions = defaultOptions,
  ): Promise<T> {
    return this.request('POST', path, body, options.headers);
  }

  public static async patch<T>(
    path: string,
    body: RequestBody,
    options: IRequestOptions = defaultOptions,
  ): Promise<T> {
    return this.request('PATCH', path, body, options.headers);
  }

  public static async put<T>(
    path: string,
    body: RequestBody,
    options: IRequestOptions = defaultOptions,
  ): Promise<T> {
    return this.request('PUT', path, body, options.headers);
  }

  public static async delete<T>(
    path: string,
    options: IRequestOptions = defaultOptions,
  ): Promise<T> {
    return this.request('DELETE', path, {}, options.headers);
  }

  public static getCSRFConfig(): { param: string, token: string } {
    const csrfParam: HTMLMetaElement = document.querySelector('meta[name=csrf-param]');
    const csrfToken: HTMLMetaElement = document.querySelector('meta[name=csrf-token]');
    if (csrfParam == null || csrfToken == null) {
      return { param: 'csrf_token', token: 'unavailable' };
    }

    return { param: csrfParam.content, token: csrfToken.content };
  }

  private static async request(
    method: string,
    path: string,
    body: RequestBody = undefined,
    headers: IRequestHeaders = {},
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await fetch(path, {
          method,
          credentials: 'same-origin',
          cache: 'no-cache',
          headers,
          body: this.processRequestBody(body),
          redirect: 'follow', // manual, *follow, error
          referrer: 'no-referrer', // no-referrer, *client
        });

        resolve(await this.processResponseBody(res));
      } catch (error) {
        reject(error);
      }
    });
  }

  private static verifyBody(body: RequestBody): RequestBody {
    if (body === undefined) return;
    const { param, token } = this.getCSRFConfig();
    if (body instanceof FormData) {
      body.append(param, token);
      return body;
    }
    return Object.assign({}, body, { [param]: token });
  }

  private static processRequestBody(body: RequestBody): string | FormData {
    if (body === undefined) return;
    const verifiedBody = this.verifyBody(body);
    if (body instanceof FormData) return verifiedBody as FormData;
    return JSON.stringify(verifiedBody);
  }

  private static async processResponseBody(res: Response): Promise<any> {
    let data: any;
    if (res.status === 204) return {};
    if (res.headers.get('content-type').match(/application\/json/)) {
      data = await res.json();
    } else {
      if (res.status === 204) return;
      data = await res.blob();
    }

    if (res.status === 401) {
      window.location.replace('/');
    } else if (res.status === 422) {
      throw new UnprocessableEntityError(data);
    } else if (res.status !== 200 && res.status !== 201) {
      throw new ApiError(res.status, data);
    }

    return data;
  }
}
