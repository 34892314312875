import React, { useCallback, useState } from 'react';
import Select from 'react-select';
import { Commit } from '../../entities/Commit';
import { ProjectService } from '../../services/ProjectService';

interface SelectOption {
  label: string;
  value: string;
}

export interface PropTypes {
  name: string;
  className: string;
  projectId: number;
  newerThanSha?: string;
  value?: string;
}

const buildLabel = (commit: Commit): string => {
  return `(${commit.sha.slice(0, 8)}) ${commit.title}`;
}

const buildOption = (commit: Commit): SelectOption => {
  if (commit === undefined) return;
  return { label: buildLabel(commit), value: commit.sha };
}

const filterOlderCommits = (sha: String, commits: Commit[]) => {
  if (sha === undefined) return commits;
  const commit = commits.find((c) => c.sha === sha);
  if (commit === undefined) return commits;

  const commitDate = new Date(commit.commit_date);
  return commits.filter((c) => {
    return (new Date(c.commit_date)) > commitDate
  });
}

export function CommitSelectContainer(props: PropTypes) {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [value, setValue] = useState<SelectOption>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loadCommits = useCallback(async () => {
    if (options.length > 0) return;

    setIsLoading(true);
    const commits = await ProjectService.listDiffCommits(props.projectId);
    if (value === undefined) setValue(buildOption(commits.find((c) => c.sha === props.value)));
    setOptions(filterOlderCommits(props.newerThanSha, commits).map((c) => buildOption(c)));
    setIsLoading(false);
  }, [props.projectId, props.value]);

  return <Select
    name={props.name}
    className={props.className}
    options={options}
    value={value}
    isSearchable
    isClearable
    isMulti={false}
    isLoading={isLoading}
    placeholder={'Select a commit...'}
    onFocus={loadCommits}
    onChange={(v) => setValue(v)}
  />;
}
