import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['commitRow'];

  commitRowTargetConnected(el: HTMLTableRowElement) {
    this.setup();
  }

  private

  setup() {
    const requiredCommitRow = this.element.querySelector('tr.commit-required');
    if (!requiredCommitRow) return;

    const rows = this.element.querySelectorAll('tr');

    for (const row of rows) {
      if (row === requiredCommitRow) break;

      row.classList.add('commit-wait-required');
    }
  }

  reset() {
    this.element.querySelectorAll('tr')
      .forEach((row) => row.classList.remove('commit-required'));
  }
}
