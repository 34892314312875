import React, { useCallback, useEffect, useState } from 'react';
import { MentionsInput, Mention, SuggestionDataItem } from 'react-mentions'
import cssStyles from './cssStyles';
import styles from './styles.module.scss';
import { UserService } from '../../services/UserService';

export interface MentionsInputPropTypes {
  name?: string;
  value?: string;
  required?: boolean;
  placeholder?: string;
}

export const MentionsInputContainter = (props: MentionsInputPropTypes) => {
  const [data, setData] = useState<SuggestionDataItem[]>([]);
  const [value, setValue] = useState<string>(props.value);

  useEffect(() => {
    (async () => {
      const users = await UserService.listForMentions();
      const options = users
        .map(u => ({ id: u.id, display: u.display_name }))
        .sort((a, b) => a.display.localeCompare(b.display));
      setData(options);
    })();
  }, []);

  const handleChange = useCallback((event: { target: { value: string } }) => {
   setValue(event.target.value);
  }, []);

  const displayTransform = (id: string, display: string) => {
    const user = data.find(v => v.id === parseInt(id));
    if (user === undefined) return display;

    return user.display;
  }

  return (
    <>
      <input type="hidden" name={props.name} value={value} />
      <MentionsInput
        className={styles.mentionsInput}
        required={props.required}
        placeholder={props.placeholder}
        style={cssStyles}
        value={value}
        onChange={handleChange}
      >
        <Mention
          trigger="@"
          displayTransform={displayTransform}
          data={data}
          className="template-badge"
          style={cssStyles.highlight}
        />
      </MentionsInput>
    </>
  );
};
