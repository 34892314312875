// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable';
import Rails from '@rails/ujs';
import '@hotwired/turbo-rails';
import * as ActiveStorage from '@rails/activestorage';
import 'chartkick/chart.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap';
import '../stylesheets/application.scss';
import 'channels';
import '../controllers';
import { PluginManager } from '../plugins';
import { CommitSelectPlugin } from '../plugins/CommitSelectPlugin';
import { BootstrapTooltipPlugin } from '../plugins/BootstrapTooltipPlugin';
import { CronParserPlugin } from '../plugins/CronParserPlugin';
import { MentionsInputPlugin } from '../plugins/MentionsInputPlugin';
import { MentionTextPlugin } from '../plugins/MentionTextPlugin';
import { AutoOpenModalPlugin } from '../plugins/AutoOpenModalPlugin';

Rails.start();
ActiveStorage.start();

(require as any).context('../images', true);

const pluginManager = new PluginManager();
pluginManager.register(new AutoOpenModalPlugin());
pluginManager.register(new BootstrapTooltipPlugin());
pluginManager.register(new CommitSelectPlugin());
pluginManager.register(new MentionsInputPlugin());
pluginManager.register(new MentionTextPlugin());
pluginManager.register(new CronParserPlugin());
pluginManager.install();
