import { Controller } from '@hotwired/stimulus';
import { Repository } from '../entities/Repository';
import { ApiService } from '../services/ApiService';

export default class extends Controller {
  static targets = ['identityId', 'repos', 'refreshRepos'];

  connect() {
    this.refreshReposButton.addEventListener('click', (e) => {
      e.preventDefault();
      this.refreshRepos();
    });
    this.identityIdSelect.addEventListener('change', () => this.refreshRepos());
    this.refreshRepos();
  }

  private async refreshRepos() {
    try {
      this.setRefreshReposSelectLoadingState();
      await this.loadRepoOptions();
    } catch(err) {
      this.setFailedReposLoadState();
    }
  }

  private setDefaultRepoState() {
    this.reposSelect.innerHTML = '';
    const defaultOption = document.createElement('option');
    defaultOption.selected = true;
    defaultOption.textContent = 'Select a connector above...';
    this.reposSelect.append(defaultOption);
  }

  private setRefreshReposSelectLoadingState() {
    this.reposSelect.innerHTML = '';
    const loadingOption = document.createElement('option');
    loadingOption.selected = true;
    loadingOption.textContent = 'Loading...';
    this.reposSelect.append(loadingOption);
  }

  private setFailedReposLoadState() {
    this.reposSelect.innerHTML = '';
    const failedOption = document.createElement('option');
    failedOption.selected = true;
    failedOption.textContent = 'Failed to load repositories. Please try again.';
    this.reposSelect.append(failedOption);
  }

  private async loadRepoOptions() {
    const identityId = this.identityIdSelect.value;
    if (identityId === '' || identityId === undefined) return this.setDefaultRepoState();

    const response = await ApiService.get<Repository[]>(
      `/identities/${identityId}/repositories`,
      { id: this.reposSelect.dataset.integrationId },
    );

    this.reposSelect.innerHTML = '';
    response
      .sort((a, b) => a.full_name.localeCompare(b.full_name))
      .map((v) => {
        const option = document.createElement('option');
        option.value = v.full_name;
        option.textContent = v.full_name;
        option.selected = v.full_name === this.reposSelect.dataset.value;
        return option;
      })
      .forEach((v) => this.reposSelect.append(v));
  }

  private get identityIdSelect(): HTMLSelectElement {
    return (this as any).identityIdTarget;
  }

  private get reposSelect(): HTMLSelectElement {
    return (this as any).reposTarget;
  }

  private get refreshReposButton(): HTMLAnchorElement {
    return (this as any).refreshReposTarget;
  }
}
