export default {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
  },
  highlight: {
    borderBottom: '1px dashed black',
    fontWeight: 400,
    padding: '2px 0px',
    position: 'relative',
    zIndex: 0,
  },
  '&multiLine': {
    control: {
      fontFamily: 'monospace',
      minHeight: 63,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      border: '1px solid silver',
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    list: {
      maxHeight: '240px',
      overflowY: 'scroll',
      minWidth: '150px',
      backgroundColor: 'white',
      boxShadow: '2px 4px 10px 4px rgba(0, 0, 0, 0.05)',
      padding: '10px',
      borderRadius: '4px',
      border: '1px solid transparent',
    },
    item: {
      border: 'none',
      borderRadius: '4px',
      color: '#2F2F2F',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '1.75',
      marginTop: '4px',
      padding: '8px',
      '&focused': {
        background: '#F7FBFD',
        color: '#308EE0',
      },
    },
  },
}
