import { ReactPlugin } from '../ReactPlugin';
import { MentionText, MentionTextPropTypes } from '../../components/MentionText';

export class MentionTextPlugin extends ReactPlugin<HTMLInputElement, MentionTextPropTypes> {
  protected get attributeFilter() {
    return 'data-mention-text';
  }

  protected get component() {
    return MentionText;
  }
}
