import cronstrue from 'cronstrue';
import { AppPlugin } from '..';

export class CronParserPlugin implements AppPlugin {

  public load(): void {
    document.querySelectorAll<HTMLInputElement>('[data-cron-parser]')
      .forEach((el) => this.installParser(el));
  }

  private installParser(input: HTMLInputElement): void {
    input.addEventListener('change', () => this.handleChange(input));
    input.addEventListener('keyup', () => this.handleChange(input));
    this.handleChange(input)
  }

  private handleChange(el: HTMLInputElement): void {
    const target = document.querySelector(el.dataset.cronParser);
    if (target === undefined) return;

    try {
      target.innerHTML = cronstrue.toString(el.value);
    } catch {
      target.innerHTML = '';
    }
  }
}
