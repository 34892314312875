import * as bootstrap from 'bootstrap';
import { AppPlugin } from '..';

export class AutoOpenModalPlugin implements AppPlugin {
  public load(): void {
    Array.from(document.querySelectorAll<HTMLElement>('.modal.open'))
      .forEach((modalEl) => {
        const modal = new bootstrap.Modal(modalEl);
        modal.show();
      });
  }
}
