import { User } from '../../entities/User';
import { ApiService } from '../ApiService';

interface UsersResponse {
  users: User[];
}

export class UserService {
  public static async listForMentions(): Promise<User[]> {
    const result = await ApiService.get<UsersResponse>('/users/mentions');
    return result.users;
  }
}
