import * as bootstrap from 'bootstrap';
import { AppPlugin } from '..';

export class BootstrapTooltipPlugin implements AppPlugin {
  public install() {
    const observer = new MutationObserver(() => this.load());
    observer.observe(document, {
      attributes: true,
      childList: true,
      subtree: true,
      attributeFilter: ['data-toggle'],
    });
  }

  public load(): void {
    Array.from(document.querySelectorAll<HTMLElement>('[data-toggle="tooltip"]'))
      .forEach((tooltipTriggerEl) => {
        new bootstrap.Tooltip(tooltipTriggerEl);
        delete tooltipTriggerEl.dataset['toggle'];
      });
  }
}
