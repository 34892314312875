import { ReactPlugin } from '../ReactPlugin';
import { MentionsInputContainter, MentionsInputPropTypes } from './MentionsInput';

export class MentionsInputPlugin extends ReactPlugin<HTMLInputElement, MentionsInputPropTypes> {
  protected get attributeFilter() {
    return 'data-mentions-input';
  }

  protected get component() {
    return MentionsInputContainter;
  }

  protected getElementProps(el: HTMLInputElement): MentionsInputPropTypes {
    return { ...super.getElementProps(el), name: el.name, value: el.value };
  }
}
