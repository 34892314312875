export class ApiError extends Error {
  public status: number;
  public body: any;

  constructor(status: number, body: any) {
    super(`API returned error status ${status}`);
    this.status = status;
    this.body = body;
  }
}
