export interface AppPlugin {
  install?: () => void;
  load?: () => void;
}

export class PluginManager {
  private plugins: AppPlugin[] = [];

  public register(plugin: AppPlugin): void {
    this.plugins.push(plugin);
  }

  public install(): void {
    this.plugins.forEach((plugin) => plugin.install && plugin.install());
    document.addEventListener('turbo:load', () => this.loadAll());
    document.addEventListener('turbo-frame:connected', () => this.loadAll());
  }

  private loadAll(): void {
    this.plugins.forEach((plugin) => plugin.load && plugin.load());
  }
}
