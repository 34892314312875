import { ReactPlugin } from '../ReactPlugin';
import { CommitSelectContainer, PropTypes } from './CommitSelectContainer';

export class CommitSelectPlugin extends ReactPlugin<HTMLSelectElement, PropTypes> {
  protected get attributeFilter() {
    return 'data-commit-select-plugin';
  }

  protected get component() {
    return CommitSelectContainer;
  }

  protected getElementProps(el: HTMLSelectElement): PropTypes {
    const projectId = parseInt(el.dataset.projectId, 10);
    const value = el.dataset.value || undefined;
    const newerThanSha = el.dataset.newerThanSha || undefined;

    return {
      name: el.name,
      className: el.className,
      projectId,
      value,
      newerThanSha,
    };
  };
}
