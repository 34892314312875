import React from 'react';
import ReactDOM from 'react-dom';
import { AppPlugin } from '..';

export abstract class ReactPlugin<T extends HTMLElement, Z> implements AppPlugin {
  public install() {
    const observer = new MutationObserver(() => this.load());
    observer.observe(document, {
      attributes: true,
      childList: true,
      subtree: true,
      attributeFilter: [this.attributeFilter],
    });
  }

  public load(): void {
    document.querySelectorAll<T>(`[${this.attributeFilter}]`)
      .forEach((el) => this.installContainer(el));
  }

  protected abstract get attributeFilter(): string;

  protected abstract get component(): (props: Z) => React.ReactElement;

  protected getElementProps(el: T): Z {
    const propsStr = el.dataset.props || '{}';
    const props = JSON.parse(propsStr);
    return {
      className: el.className,
      ...props,
    };
  };

  protected installContainer(el: T): void {
    const containerEl = document.createElement('span');
    el.before(containerEl);

    ReactDOM.render(React.createElement(this.component, this.getElementProps(el)), containerEl);
    el.remove();
  }
}
